<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <div class="main">
        <v-row no-gutters justify="center">

          <v-col cols="12" sm="6" md="4" class="mb-3">
            <v-card class="shadow rounded-lg">
              <v-card-text class="pa-6">
                <v-row>
                  <v-col>
                    <v-skeleton-loader v-if="isLoading" type="text"/>
                    <h3 class="mb-2 text-no-wrap" v-else>
                      {{ CurrencyFormatting(sum_transactions_amount) }} DZD
                    </h3>
                    <div class="font-weight-medium">
                      Total à recouvrir
                    </div>
                  </v-col>
                  <v-col class="text-right">
                    <v-avatar color="primary">
                      <v-icon dark>mdi-cash-check</v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="rounded-lg shadow">
              <v-card-title>
                <div>
                  <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line

                  />
                </div>
                <v-spacer/>

                <v-btn color="grey" text @click="getCibRecoveries">
                  <v-icon left>mdi-refresh</v-icon>
                  Mettre à jour
                </v-btn>
                &nbsp;
                <v-btn color="gifty"
                       dark
                       v-if="can('admin')"
                       class="rounded-lg"
                       depressed
                       @click="$refs.addCIBPaymentDialog.open(sum_transactions_amount)">
                  <v-icon left>mdi-plus</v-icon>
                  Ajouter un paiement
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>
                <v-card-text class="pa-0">
                  <v-data-table :headers="filteredHeaders"
                                :items="cib_recoveries"
                                :search="keyword">

                    <template v-slot:item.old_amount="{ item }">
                      <span class="font-weight-medium text-no-wrap">
                      {{ CurrencyFormatting(item.old_amount) }}
                      </span>
                    </template>

                    <template v-slot:item.paid_amount="{ item }">
                      <span class="font-weight-medium gifty--text text-no-wrap">
                      {{ CurrencyFormatting(item.paid_amount) }}
                      </span>
                    </template>

                    <template v-slot:item.remaining_amount="{ item }">
                      <div class="info--text font-weight-medium text-no-wrap">
                        {{
                          (item.paid_amount - item.old_amount) > 0 ? '+' :
                              (item.paid_amount - item.old_amount) === 0 ? '' : '-'
                        }}{{ CurrencyFormatting(Math.abs(item.old_amount - item.paid_amount)) }}
                      </div>
                    </template>


                    <template v-slot:item.actions="{ item }">
                      <v-btn icon disabled>
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                      <v-btn icon @click="remove(item.id)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>

                  </v-data-table>
                </v-card-text>

              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <AddCIBPaymentDialog ref="addCIBPaymentDialog" @refresh="getCibRecoveries"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import moment from "moment";
import AddCIBPaymentDialog from "@/views/recoveries/components/AddCIBPaymentDialog";

export default {
  components: {AddCIBPaymentDialog},
  data() {
    return {
      id: null,
      keyword: null,
      overlay: false,
      isLoading: false,
      sum_transactions_amount: 0,
      cib_recoveries: [],
      headers: [
        {
          text: 'Ancien total',
          value: 'old_amount',
        },
        {
          text: 'Montant payé',
          value: 'paid_amount',
        },
        {
          text: 'Écart',
          value: 'remaining_amount',
        },
        {
          text: 'Remarque',
          value: 'remark',
        },
        {
          text: 'Date de création',
          value: 'created_at',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ]

    };
  },
  methods: {
    getCibRecoveries() {
      this.isLoading = true
      HTTP.get('/v1/recoveries/cib').then(res => {
        this.isLoading = false
        this.sum_transactions_amount = res.data.data.sum_transactions_amount
        this.cib_recoveries = res.data.data.cib_recoveries
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;

      HTTP.post("/v1/recoveries/cib/delete", {
        id: this.id
      }).then(() => {
        this.overlay = false;
        this.getCibRecoveries();
        this.$successMessage = 'Cette paiement a été supprimée avec succès.'
        this.id = null;
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm');
    }
  },
  watch: {
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    },
  },
  created() {
    this.getCibRecoveries();
  },
  computed: {
    filteredHeaders() {
      if (!this.can('admin')) {
        return this.headers.filter(header => header.value !== 'actions');
      }
      return this.headers;
    },
  },
};
</script>

<style scoped></style>
