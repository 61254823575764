<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="600"
    >
      <v-card>

        <v-card-title>
          Ajouter un paiement
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

<!--          <v-autocomplete :items="suppliers"-->
<!--                          item-text="name"-->
<!--                          item-value="id"-->
<!--                          dense-->
<!--                          outlined-->
<!--                          label="Fourniseur *"-->
<!--                          :error-messages="errors.supplier_id"-->
<!--                          v-model="form.supplier_id"/>-->

          <v-text-field disabled
                        dense
                        filled
                        label="Ancien Montant *"
                        :error-messages="errors.old_amount"
                        v-model="form.old_amount"/>

          <v-text-field label="Montant à Payé *"
                        outlined
                        dense
                        :error-messages="errors.paid_amount"
                        v-model="form.paid_amount"/>

          <v-text-field disabled
                        dense
                        filled
                        label="Écart *"
                        :error-messages="errors.remaining_amount"
                        v-model="form.remaining_amount"/>

          <v-textarea rows="3"
                      label="Remarque"
                      outlined
                      hide-details="auto"
                      placeholder="Écrire une remarque ici ..."
                      :error-messages="errors.remark"
                      prepend-inner-icon="mdi-pencil-box-outline"
                      v-model="form.remark"/>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 @click="save"
                 :loading="btnLoading"
                 depressed>
            <v-icon left>mdi-content-save</v-icon>
            Sauvgarder
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dataLoading: false,
      btnLoading: false,
      dialog: false,
      isLoading: false,
      form: {
        remark: null,
        old_amount: 0,
        paid_amount: 0,
        remaining_amount: 0,
      },
      errors: {},
      suppliers: [],
    }
  },
  watch: {
    'form.old_amount': 'calculateRemainingAmount',
    'form.paid_amount': 'calculateRemainingAmount',
  },
  methods: {
    calculateRemainingAmount() {
      this.form.remaining_amount = this.form.old_amount - this.form.paid_amount;
    },
    open(sumTransactionsAmount) {
      this.getSuppliers()
      this.errors = {}
      this.dialog = true
      this.form.old_amount = sumTransactionsAmount
      this.form.paid_amount = sumTransactionsAmount
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.btnLoading = true
      HTTP.post('/v1/recoveries/cib/store', this.form).then(() => {
        this.isLoading = false
        this.btnLoading = false
        this.$successMessage = 'Cette paiement a été ajoutée avec succès.'
        this.close()
        this.$emit('refresh')
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.btnLoading = false
        console.log(err)
      })
    },
    getSuppliers() {
      this.dataLoading = true
      HTTP.get('/manager/vouchers/suppliers').then(res => {
        this.dataLoading = false
        this.suppliers = res.data
      }).catch(err => {
        this.dataLoading = false
        console.log(err)
      })
    },
  },

}
</script>

<style scoped>

</style>